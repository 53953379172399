import React, { useEffect, useState } from "react";
import axios from 'axios';
import Header from "../components/header";
import Footer from "../components/footer";
import download from "../assets/images/download.png";
import cricket from "../assets/images/cricket.png";
import MainSection from "../components/MainSection";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import { Helmet } from "react-helmet";

const Signuppage = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/home");
    }
  }, [navigate]);

  const handleCloseErrorModal = () => setShowErrorModal(false);

  const validateInput = () => {
    if (!fullName || !emailAddress || !whatsappNumber || !password) {
      setError('All fields are required.');
      setShowErrorModal(true);
      return false;
    }
    if (!emailAddress.includes('@') || !emailAddress.includes('.')) {
      setError('Invalid email address.');
      setShowErrorModal(true);
      return false;
    }
    if (whatsappNumber.length < 7 || whatsappNumber.length > 15) {
      setError('Whatsapp number must be between 6 and 15 digits.');
      setShowErrorModal(true);
      return false;
    }
    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      setShowErrorModal(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateInput()) return;

    setLoading(true);
    setError('');
    const params = new URLSearchParams();
    params.append('user_email', emailAddress);
    params.append('user_whatsapp', whatsappNumber);
    params.append('user_fullname', fullName);
    params.append('user_real_pass', password);
    params.append('table_name', 'users');

    try {
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 20000); // 20 seconds timeout

      const response = await axios.post('https://api.betprowallet.pk/createNewAccUserFromServer.php', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cancelToken: source.token,
      });

      clearTimeout(timeout);

      console.log('Response data:', response.data);

      if (response.data.status === 'success') {
      
      
        localStorage.removeItem('user');

        const user = {
          name: response.data.data[0]?.user_fullname || 'User',
          bp_username: response.data.data[0]?.user_betpro_username || '',
          bp_pass: response.data.data[0]?.user_betpro_password || '',
          email: response.data.data[0]?.user_email || '',
          password: '',
          number: response.data.data[0]?.user_whatsapp || 'N/A',
          userID: response.data.data[0]?.user_id || 'N/A',
          userActive: response.data.data[0]?.user_active || 0,
      };

    

      localStorage.setItem('user', JSON.stringify(user));
     

  
        console.log('User data:', response.data.data[0]);
        navigate('/home');


      

      } else {
        setError(response.data.message || 'Sign up failed. Please try again.');
        setShowErrorModal(true);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setError('Request timed out. Please try again.');
      } else {
        setError('Please check your internet connection...');
      }
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handlebuttonClick = () => {
    navigate('/');
  };

  return (
    <div>

<Helmet>
  {/* Page title optimized with relevant keywords */}
  <title>Betpro Wallet Signup - Bpexch</title>
  <link rel="icon" href="https://betprowallet.pk/favicon.ico" />

   {/* Canonical tag */}
   <link rel="canonical" href="https://betprowallet.pk/signup" />
  
  {/* Meta description summarizing the page content */}
  <meta name="description" content="Betpro Wallet Pakistan registration. ⚽️ Register your Betpro Wallet account by phone number and claim a sign-up bonus. ✓ Create your account now and start betting on sports with Betprowallet.pk. Secure and fast registration process." />
  
  {/* Meta keywords targeting search queries related to registration */}
  <meta name="keywords" content="Betpro Wallet sign up, Betpro Wallet registration, create Betpro Wallet account, sign up bonus, register Betpro Wallet Pakistan, Betpro Wallet phone registration, online betting platform, Betprowallet.pk sign up" />
  
  {/* Meta author */}
  <meta name="author" content="Betpro Wallet" />
  
  {/* Open Graph meta tags for social media */}
  <meta property="og:title" content="Betpro Wallet Signup - Bpexch" />
  <meta property="og:description" content="Register for Betpro Wallet in Pakistan and claim your sign-up bonus. Create your account today and start betting on sports with secure and fast withdrawals." />
  <meta property="og:url" content="https://betprowallet.pk/signup" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://betprowallet.pk/bpthumb1.jpg" />
  
  {/* Twitter Card for social sharing */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Betpro Wallet Signup - Bpexch" />
  <meta name="twitter:description" content="Sign up for Betpro Wallet in Pakistan. Get a sign-up bonus and start betting on your favorite sports. Secure, fast, and easy registration process." />
  <meta name="twitter:image" content="https://betprowallet.pk/images/signup-twitter-image.jpg" />
  
  {/* Structured Data for SEO */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Betpro Wallet Signup - Bpexch",
        "url": "https://betprowallet.pk/signup",
        "description": "Betpro Wallet registration page. Create an account and get a sign-up bonus to start betting on sports today. Secure and fast registration process in Pakistan.",
        "publisher": {
          "@type": "Organization",
          "name": "Betpro Wallet"
        }
      }
    `}
  </script>
</Helmet>



      <div className="register-wrapper2">
        <div className="main-register">
          <div className="container">
            <div className="row align-items-top">
              <div className="col-md-12">
                <div className="left-register-box">
                  <div className="fieldset"  style={{
                                    padding: '10px 0px',
                                  }}>
                    <div className="title mb-5">
                      <h2>Register your Betpro account</h2>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="inputs field-container">
                          <form onSubmit={handleSubmit}>
                            <input
                              type="text"
                              className="mb-4"
                              placeholder="Full Name"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                            <input
                              type="text"
                              className="mb-4"
                              placeholder="Email Address"
                              value={emailAddress}
                              onChange={(e) => setEmailAddress(e.target.value)}
                            />
                            <input
                              type="number" 
                              className="mb-4"
                              placeholder="Whatsapp Number"
                              value={whatsappNumber}
                              onChange={(e) => setWhatsappNumber(e.target.value)}
                            />
                            <input
                              type="password"
                              className="mb-4"
                              placeholder="Enter password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <button type="submit" className="btn-signin mb-3" disabled={loading}>
                              {loading ? 'Loading...' : 'Sign up'}
                            </button>
                          </form>
                          <a href="/" className="signup mb-3">
                            Already have an account?
                          </a>
                          <button className="btn-create-account mb-3" onClick={handlebuttonClick}>
                            Sign in
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainSection />
      <Footer />
      <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered>
        <Modal.Header >
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{error}</p>
          <hr/>
          <p onClick={handleCloseErrorModal} className="model-ok">Ok</p>
        </Modal.Body>
       
      </Modal>
    </div>
  );
};

export default Signuppage;
